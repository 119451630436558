import ReactCountdown from "react-countdown";
import { ClientOnly } from "remix-utils/client-only";

export type CountdownProps = {
  date: string | number | Date;
};

export const Countdown = ({ date }: CountdownProps) => {
  return (
    <ClientOnly fallback={null}>
      {() => (
        <ReactCountdown className="text-xl font-medium md:text-5xl" date={date}>
          <></>
        </ReactCountdown>
      )}
    </ClientOnly>
  );
};
