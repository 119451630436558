export const Smartsupp = () => {
  return (
    <>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `var _smartsupp = _smartsupp || {}; _smartsupp.key = 'd684e9ef5ef0a1a730313637f53acde1ab85b4f1'; window.smartsupp||(function(d) { var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[]; })(document); `,
        }}
      ></script>
      <script
        async={true}
        type="text/javascript"
        src="https://www.smartsuppchat.com/loader.js?"
      ></script>
    </>
  );
};
