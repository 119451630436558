import { useTranslation } from "react-i18next";

export const Maintenance = () => {
  const { t } = useTranslation();
  return (
    <div className="fixed top-0 bottom-0 left-0 right-0 flex flex-col items-center justify-center gap-8 overflow-hidden text-white bg-black">
      <h1 className="relative z-20 text-5xl font-medium">
        {t("components.atoms.maintenance.maintenance")}
      </h1>
      <div className="relative z-20 max-w-lg text-xl text-center">
        {t("components.atoms.maintenance.maintenanceDescription")}
      </div>
      <img
        draggable={false}
        className="absolute top-0 bottom-0 left-0 right-0 object-cover w-screen h-screen scale-105 select-none opacity-20 blur-sm"
        src="/images/hero.png"
        alt=""
      />
    </div>
  );
};
