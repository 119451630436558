import { cn } from "~/utils";

export type LogoProps = {
  className?: string;
  isSimple?: boolean;
};

export const Logo = ({ className, isSimple }: LogoProps) => {
  return (
    <div className={cn("relative z-10 w-full", className)}>
      <img
        className="w-full"
        src={isSimple ? "/images/logo-simple.png" : "/images/logo.png"}
        alt="Zraz Motorkárov Zemplínska Šírava"
      />
    </div>
  );
};
