import { Link, useLocation } from "@remix-run/react";
import { MenuIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { cn } from "~/utils";
import { Logo } from "../atoms/logo";
import { NavigationButton } from "../atoms/navigation-button";
import { NavigationCart } from "~/components/molecules/navigation-cart";
import { ClientOnly } from "remix-utils/client-only";
import { Button } from "../ui/button";
import { LocaleLink, LocaleNavLink } from "../atoms/locale-link";
import { useTranslation } from "react-i18next";

export type HeaderProps = {
  navItems: { title: string; route: string }[];
  ticketsShopUrl?: string;
};

export const Header = ({ navItems, ticketsShopUrl }: HeaderProps) => {
  const [isNavigationOpen, setNavigationOpen] = useState(false);
  const { t } = useTranslation();

  const handleCloseNavigation = () => {
    setNavigationOpen(false);
  };

  const handleToggleNavigation = () => {
    setNavigationOpen((isNavigationOpen) => !isNavigationOpen);
  };

  const location = useLocation();

  useEffect(handleCloseNavigation, [location.pathname]);

  return (
    <div className="sticky top-0 left-0 right-0 z-20 h-24 text-white bg-black/80 backdrop-blur-md lg:h-20">
      <div className="container flex items-center justify-between h-full">
        <button
          className={cn(
            "fixed top-0 right-0 left-0 h-screen bg-black/70 transition-all backdrop-blur-sm",
            {
              "visible opacity-100": isNavigationOpen,
              "invisible opacity-0": !isNavigationOpen,
            },
          )}
          onClick={handleCloseNavigation}
        ></button>

        <NavigationButton
          className="lg:hidden"
          onClick={handleToggleNavigation}
        >
          <MenuIcon />
        </NavigationButton>

        <div className="relative z-10 flex flex-col items-center gap-2 lg:gap-4 lg:flex-row">
          <LocaleLink unstable_viewTransition route="home">
            <Logo isSimple className="max-w-48" />
          </LocaleLink>
          {ticketsShopUrl && (
            <Button size="sm" variant="secondary" asChild>
              <Link target="_blank" to={ticketsShopUrl} rel="noreferrer">
                {t("components.layout.header.buyTickets")}
              </Link>
            </Button>
          )}
        </div>

        <div className="flex items-center">
          <nav
            className={cn(
              "fixed z-30 top-24 left-0 h-screen lg:h-auto bg-black lg:bg-transparent w-64 lg:w-auto transition-transform lg:static lg:translate-x-0 lg:transition-none",
              {
                "translate-x-0": isNavigationOpen,
                "-translate-x-full": !isNavigationOpen,
              },
            )}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */}
            <ul
              onClick={(e) => e.stopPropagation()}
              className="flex flex-col flex-wrap justify-end px-8 py-4 gap-x-2 lg:items-center lg:flex-row lg:p-0 lg:pr-2"
            >
              {navItems.map((navItem, index) => (
                <li key={index}>
                  <LocaleNavLink
                    unstable_viewTransition
                    prefetch="intent"
                    className={({ isActive }) =>
                      cn(
                        "px-2 text-lg inline-block uppercase hover:text-primary transition-colors whitespace-nowrap",
                        {
                          "text-primary": isActive,
                        },
                      )
                    }
                    route={navItem.route}
                  >
                    {navItem.title}
                  </LocaleNavLink>
                </li>
              ))}
              <li>
                <Link
                  to="/#ubytovanie"
                  className="inline-block px-2 text-lg uppercase transition-colors hover:text-primary whitespace-nowrap"
                >
                  Ubytovanie
                </Link>
              </li>
              <li>
                <Link
                  unstable_viewTransition
                  to="/o-zraze"
                  className="inline-block px-2 text-lg uppercase transition-colors hover:text-primary whitespace-nowrap"
                >
                  O zraze
                </Link>
              </li>
            </ul>
          </nav>
          <ClientOnly fallback={<div />}>{() => <NavigationCart />}</ClientOnly>
        </div>
      </div>
    </div>
  );
};
