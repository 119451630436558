import { ReactNode } from "react";
import { cn } from "~/utils";

export type NavigationButtonProps = {
  children?: ReactNode;
  number?: number;
  onClick?: () => void;
  className?: string;
};

export const NavigationButton = ({
  children,
  number,
  onClick,
  className,
}: NavigationButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        "relative flex-shrink-0 w-12 h-12 flex items-center justify-center transition-colors hover:bg-white/20 rounded-full",
        className,
      )}
    >
      {children}
      {number !== undefined && number !== 0 && (
        <div className="absolute top-0 right-0 flex items-center justify-center w-6 h-6 text-sm font-bold rounded-full bg-primary">
          {number}
        </div>
      )}
    </button>
  );
};
