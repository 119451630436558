import { ShoppingCartIcon } from "lucide-react";
import { NavigationButton } from "~/components/atoms/navigation-button";
import { cart } from "../../services/cart.client";
import { LocaleLink } from "../atoms/locale-link";

export const NavigationCart = () => {
  const cartItemsCount = cart.useCart((cart) => cart.itemsCount());
  return (
    <LocaleLink unstable_viewTransition prefetch="intent" route="cart">
      <NavigationButton number={cartItemsCount}>
        <ShoppingCartIcon />
      </NavigationButton>
    </LocaleLink>
  );
};
