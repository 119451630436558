import { cssBundleHref } from "@remix-run/css-bundle";
import type {
  LinksFunction,
  LoaderFunctionArgs,
  MetaFunction } from
"@remix-run/node";
import {
  Links,
  LiveReload,
  Meta,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  json,
  useLoaderData,
  useLocation,
  useOutlet,
  useRouteError,
  useRouteLoaderData } from
"@remix-run/react";
import { Toaster } from "~/components/ui/sonner";

import styles from "./styles/globals.css";
import { Layout } from "./components/layout/layout";
import { MotionConfig } from "framer-motion";
import { createApiClient } from "./services/api";

import { useTranslation } from "react-i18next";
import { useChangeLanguage } from "./utils/useChangeLanguage";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger } from
"./components/ui/accordion";
import { TooltipProvider } from "./components/ui/tooltip";
import { cart } from "./services/cart.client";
import { useEffect } from "react";
import { Button } from "./components/ui/button";
import { Image } from "./components/atoms/image";
import { Maintenance } from "./components/atoms/maintenance";
import { Smartsupp } from "./components/smartsupp";

export const handle = {
  // In the handle export, we can add a i18n key with namespaces our route
  // will need to load. This key can be a single string or an array of strings.
  // TIP: In most cases, you should set this to your defaultNS from your i18n config
  // or if you did not set one, set it to the i18next default namespace "translation"
  i18n: ["common", "zod"]
};

export const useRootLoaderData = () => {
  const data = useRouteLoaderData<typeof loader>("root");

  if (!data) throw new Error("useRootLoaderData error");

  return data;
};

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const api = createApiClient();

  // TODO: Make this proper
  const locale = request.url.includes("/en/") ? "en" : "sk";

  const [partners, settings] = await Promise.all([
  api.getPartners(),
  api.getSettings()]
  );

  return json({
    partners,
    settings,
    locale,
    recaptchaSiteKey: process.env.RECAPTCHA_SITE_KEY ?? "",
    apiBaseUrl: process.env.API_BASE_URL ?? "",
    baseUrl: new URL(request.url).origin,
    isProduction: process.env.NODE_ENV === "production"
  });
};

export const links: LinksFunction = () => [
{ rel: "stylesheet", href: styles },
...(cssBundleHref ? [{ rel: "stylesheet", href: cssBundleHref }] : []),
{ rel: "preconnect", href: "https://fonts.googleapis.com" },
{
  rel: "preconnect",
  href: "https://fonts.gstatic.com",
  crossOrigin: "anonymous"
},
{
  rel: "stylesheet",
  href: "https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,500;0,600;0,700;1,200;1,300&display=swap",
  crossOrigin: "anonymous"
}];


const PlausibleScript = () => {
  return (
    <script
      defer={true}
      data-domain="zrazmotorkarov.sk"
      src="https://plausible.io/js/script.js">
    </script>);

};

const description =
"Každý, kto miluje motorky, rýchlosť a adrenalín, jednoducho musí byť súčasťou tohto jedinečného zážitku. Neváhajte, pridajte sa k nám a zažite nezabudnuteľné momenty. Spolu sa zabavíme už od štvrtka 15.8. až do nedeľného rána 18.8.2024.";

export const meta: MetaFunction<typeof loader> = ({ data }) => {
  const socialsImageSrc = `${data?.baseUrl ?? ""}/images/socials.jpg`;
  return [
  // GENERAL
  { title: "Zraz Motorkárov" },
  {
    name: "description",
    content: description
  },
  // OPEN GRAPH
  {
    property: "og:url",
    content: `${data?.baseUrl ?? ""}`
  },
  {
    property: "og:type",
    content: "website"
  },
  {
    property: "og:title",
    content: "Zraz Motorkárov"
  },
  {
    property: "og:description",
    content: description
  },
  {
    property: "og:image",
    content: socialsImageSrc
  },
  // TWITTER
  {
    property: "twitter:card",
    content: "summary_large_image"
  },
  {
    property: "twitter:domain",
    content: `${(data?.baseUrl ?? "//").split("//")[1]}`
  },
  {
    property: "twitter:url",
    content: `${data?.baseUrl ?? ""}`
  },
  {
    name: "twitter:title",
    content: "Zraz Motorkárov"
  },
  {
    name: "twitter:description",
    content: description
  },
  {
    name: "twitter:image",
    content: socialsImageSrc
  }];

};

export default function App() {
  const outlet = useOutlet();

  const {
    partners,
    settings,
    locale,
    apiBaseUrl,
    recaptchaSiteKey,
    isProduction
  } = useLoaderData<typeof loader>();

  const { i18n } = useTranslation();

  useChangeLanguage(locale);

  const location = useLocation();

  const pageLinks = settings.footer_links.map((footerLink) => ({
    url: footerLink.link,
    label: footerLink.label,
    target: footerLink.target
  }));

  useEffect(() => {
    cart.validateCart();
  }, []);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const el = document.querySelector(location.hash);
        if (el) {
          el.scrollIntoView();
        }
      }, 100);
    }
  }, [location]);

  if (
  // TODO: Make this proper
  location.pathname.startsWith("/api/"))
  {
    return outlet;
  }

  if (settings.maintenance_mode && isProduction) {
    return (
      <html lang={locale} dir={i18n.dir()}>
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <PlausibleScript />
          <Meta />
          <Links />
        </head>
        <body className="bg-black text-foreground">
          <Maintenance />
        </body>
      </html>);

  }

  return (
    <html lang={locale} dir={i18n.dir()} className="scroll-smooth">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <PlausibleScript />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify({
              apiBaseUrl,
              recaptchaSiteKey
            })}`
          }} />

        <Smartsupp />
      </head>
      <body className="bg-black text-foreground">
        <MotionConfig reducedMotion="user">
          <TooltipProvider>
            <Layout
              partners={partners}
              settings={settings}
              pageLinks={pageLinks}>

              {outlet}
            </Layout>
          </TooltipProvider>
        </MotionConfig>
        <Toaster
          position="top-right"
          toastOptions={{
            style: {
              background: "transparent",
              marginTop: "4rem"
            }
          }} />

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}

export function ErrorBoundary() {
  const error = useRouteError();

  return (
    <html lang={"sk"} dir="ltr">
      <head>
        <title>Zraz Motorkárov | Oh no!</title>
        <PlausibleScript />
        <Meta />
        <Links />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.env = ${JSON.stringify({
              apiBaseUrl: "",
              recaptchaSiteKey: ""
            })}`
          }} />

      </head>
      <body>
        {isRouteErrorResponse(error) ?
        error.status === 404 ?
        <div className="container flex flex-col items-center justify-center gap-8 py-16">
              <Image
            className="max-w-lg contrast-200"
            src="/images/404.webp"
            alt="" />

              <div className="text-2xl text-center">
                Zdá sa, že ste sa stratili...
              </div>
              <Button asChild size="lg">
                <a href="/">Domov</a>
              </Button>
            </div> :

        <div className="container flex flex-col items-center justify-center py-24">
              <div className="text-6xl font-bold">{error.status}</div>
              <div className="text-2xl">{error.statusText}</div>
            </div> :

        error instanceof Error ?
        <div className="container flex flex-col items-center p-8">
            <div className="text-6xl font-bold">500</div>
            <h1 className="font-medium">Server Error</h1>

            <Accordion className="w-full" type="single" collapsible>
              <AccordionItem value="more-info">
                <AccordionTrigger>More info</AccordionTrigger>
                <AccordionContent>
                  <div className="flex flex-col gap-4">
                    <pre className="w-full pb-4 text-lg font-medium border-b">
                      {error.message}
                    </pre>
                    <pre className="w-full">{error.stack}</pre>
                  </div>
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </div> :

        <h1>Unknown Error</h1>}

        <Scripts />
        <LiveReload />
      </body>
    </html>);

}