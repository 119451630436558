import {
  FacebookIcon,
  InstagramIcon,
  MapPinIcon,
  PhoneIcon,
  SendIcon,
  YoutubeIcon,
} from "lucide-react";
import { Partners } from "../blocks/partners";
import { Link, useMatches } from "@remix-run/react";
import { Partner, Settings } from "~/services/api";
import { useTranslation } from "react-i18next";
import { LocaleLink } from "../atoms/locale-link";
import { cn } from "~/utils";
import { Button } from "../ui/button";

export type FooterProps = {
  settings?: Settings;
  partners?: Partner[];
  onCookiesManagementClick: () => void;
  pageLinks: {
    url: string;
    label: string;
    target: "_blank" | "_self";
  }[];
};

export const Footer = ({
  settings,
  partners,
  onCookiesManagementClick,
  pageLinks,
}: FooterProps) => {
  const { t, i18n } = useTranslation();

  const matches = useMatches();
  const routeId = matches.at(-1)?.id;
  const routeIdWithoutLang = routeId?.slice(0, -3);
  return (
    <div className="sticky top-full">
      <Partners partners={partners} />
      <div className="pt-16 pb-24 text-white bg-black">
        <div className="container flex flex-col items-center gap-12">
          {settings && (
            <div className="flex flex-col gap-8">
              <div className="flex flex-col items-center justify-center gap-8 lg:flex-row">
                <Link
                  className="flex items-center gap-2"
                  to={`tel:${settings.company_phone}`}
                >
                  <PhoneIcon className="text-primary" />
                  <span>{t("components.layout.footer.callUs")}:</span>
                  <span className="text-primary hover:underline">
                    {settings.company_phone}
                  </span>
                </Link>

                <Link
                  className="flex items-center gap-2"
                  to={`mailto:${settings.company_email}`}
                >
                  <SendIcon className="text-primary" />
                  <span>{t("components.layout.footer.writeToUs")}:</span>
                  <span className="text-primary hover:underline">
                    {settings.company_email}
                  </span>
                </Link>

                <Link
                  target="_blank"
                  className="flex items-center gap-2"
                  to={settings.event_navigation_url}
                  rel="noreferrer"
                >
                  <MapPinIcon className="text-primary" />
                  <span>{t("components.layout.footer.coordinations")}:</span>
                  <span className="text-primary hover:underline">
                    {settings.event_latitude}, {settings.event_longitude}
                  </span>
                </Link>
              </div>

              <div className="flex flex-col items-center justify-center gap-8 lg:flex-row">
                <Link
                  className="flex items-center gap-2"
                  target="_blank"
                  to={settings.company_instagram_url}
                  rel="noreferrer"
                >
                  <InstagramIcon className="text-primary" />
                  <span>Instagram:</span>
                  <span className="text-primary hover:underline">
                    {settings.company_instagram_handle}
                  </span>
                </Link>

                <Link
                  className="flex items-center gap-2"
                  target="_blank"
                  to={settings.company_facebook_url}
                  rel="noreferrer"
                >
                  <FacebookIcon className="text-primary" />
                  <span>Facebook:</span>
                  <span className="text-primary hover:underline">
                    {settings.company_facebook_handle}
                  </span>
                </Link>

                <Link
                  className="flex items-center gap-2"
                  target="_blank"
                  to={settings.company_youtube_url}
                  rel="noreferrer"
                >
                  <YoutubeIcon className="text-primary" />
                  <span>YouTube:</span>
                  <span className="text-primary hover:underline">
                    {settings.company_youtube_handle}
                  </span>
                </Link>
              </div>
            </div>
          )}
          <div className="flex flex-col items-center gap-4 text-xs lg:flex-row">
            {pageLinks.map(({ url, target, label }) => {
              const to = url
                .replace("https://www.zrazmotorkarov.sk", "")
                .replace("http://www.zrazmotorkarov.sk", "")
                .replace("https://zrazmotorkarov.sk", "")
                .replace("http://zrazmotorkarov.sk", "")
                .replace("www.zrazmotorkarov.sk", "")
                .replace("zrazmotorkarov.sk", "");

              return (
                <Link
                  unstable_viewTransition
                  key={url}
                  className="hover:underline"
                  to={to}
                  target={target}
                >
                  {label}
                </Link>
              );
            })}
          </div>
          <div className="flex flex-wrap items-center justify-center gap-4">
            <img className="w-16" src="/images/footer/visa.svg" alt="visa" />
            <img
              className="w-16"
              src="/images/footer/mastercard.svg"
              alt="mastercard"
            />
            <img
              className="w-16"
              src="/images/footer/maestro.svg"
              alt="maestro"
            />
            <img
              className="w-16"
              src="https://www.tatrabanka.sk/web/images/logo_apple_pay.svg"
              alt="apple pay"
            />
            <img
              className="w-16"
              src="https://www.tatrabanka.sk/web/images/logo_google_pay.svg"
              alt="google pay"
            />
          </div>
          <div className="flex flex-col items-center gap-4 text-xs lg:flex-row">
            <LocaleLink
              className={cn("hover:underline", {
                "text-primary": i18n.language === "sk",
              })}
              reloadDocument
              route={routeIdWithoutLang || "home"}
              locale="sk"
            >
              SK
            </LocaleLink>
            <LocaleLink
              className={cn("hover:underline", {
                "text-primary": i18n.language === "en",
              })}
              reloadDocument
              route={routeIdWithoutLang || "home"}
              locale="en"
            >
              EN
            </LocaleLink>
          </div>
          <Button onClick={onCookiesManagementClick} variant="link">
            {t("components.layout.footer.manageCookies")}
          </Button>
          <div className="flex flex-col items-center text-xs text-center text-muted-foreground">
            {settings && (
              <div>
                &copy; {new Date().getFullYear()} {settings.company_name},
              </div>
            )}
            {settings && <div>{settings.company_info}</div>}
            <div>{t("components.layout.footer.allRightsReserved")}</div>
            <div>{t("components.layout.footer.usageRestriction")}</div>
          </div>
        </div>
      </div>
    </div>
  );
};
