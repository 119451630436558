import { ReactNode, useState } from "react";
import { Header, HeaderProps } from "./header";
import { Footer } from "./footer";
import { Countdown } from "../atoms/countdown";
import { Partner, Settings } from "~/services/api";
import { useTranslation } from "react-i18next";
import { ClientOnly } from "remix-utils/client-only";
import { CookieBanner } from "../molecules/cookie-banner";

export type LayoutProps = {
  children?: ReactNode;
  partners?: Partner[];
  settings?: Settings;
  pageLinks: {
    url: string;
    label: string;
    target: "_blank" | "_self";
  }[];
};

export const Layout = ({
  children,
  partners,
  settings,
  pageLinks,
}: LayoutProps) => {
  const { t } = useTranslation();

  const navItems: HeaderProps["navItems"] = [
    {
      title: t("components.layout.layout.home"),
      route: "home",
    },
    {
      title: t("components.layout.layout.news"),
      route: "articles",
    },
    {
      title: t("components.layout.layout.shop"),
      route: "shop",
    },
    {
      title: t("components.layout.layout.camping"),
      route: "camping",
    },
    {
      title: t("components.layout.layout.maps"),
      route: "maps",
    },
    {
      title: t("components.layout.layout.program"),
      route: "program",
    },
  ];

  const [isCookiesBannerOpen, setCookiesBannerOpen] = useState(false);

  const onCookiesManagementClick = () => {
    setCookiesBannerOpen(true);
  };

  const onCookiesBannerOpenChange = (isOpen: boolean) => {
    setCookiesBannerOpen(isOpen);
  };

  return (
    <div className="h-full">
      <Header navItems={navItems} ticketsShopUrl={settings?.tickets_shop_url} />
      <div className="flex flex-col gap-16 pb-16 lg:gap-16 lg:pb-24 bg-background">
        {children}
      </div>
      <Footer
        settings={settings}
        partners={partners}
        onCookiesManagementClick={onCookiesManagementClick}
        pageLinks={pageLinks}
      />
      {settings?.countdown_datetime && (
        <div className="fixed bottom-0 z-40 flex items-center justify-center w-full py-2 text-white md:py-4 bg-black/80 backdrop-blur-md">
          <Countdown date={settings.countdown_datetime} />
        </div>
      )}
      <ClientOnly fallback={null}>
        {() => (
          <CookieBanner
            isOpen={isCookiesBannerOpen}
            onOpenChange={onCookiesBannerOpenChange}
          />
        )}
      </ClientOnly>
    </div>
  );
};
