import { useTranslation } from "react-i18next";
import { Dialog, DialogContent } from "../ui/dialog";
import { useEffect, useState } from "react";
import { Button } from "../ui/button";
import { Switch } from "../ui/switch";
import { Label } from "../ui/label";
import { useCookies } from "react-cookie";

export type CookieBannerProps = {
  isOpen: boolean;
  onOpenChange: (isOpen: boolean) => void;
};

export const CookieBanner = ({ isOpen, onOpenChange }: CookieBannerProps) => {
  const { t } = useTranslation();

  const [cookies, setCookie] = useCookies(["cookies-consent"]);

  const [mustBeOpen, setMustBeOpen] = useState(!cookies["cookies-consent"]);

  useEffect(() => {
    if (mustBeOpen) {
      onOpenChange(true);
    }
  }, [mustBeOpen, onOpenChange]);

  const [isCustomOpen, setCustomOpen] = useState(false);

  const [allowPreferenceCookies, setAllowPreferenceCookies] = useState(
    cookies["cookies-consent"]?.preference ?? true,
  );
  const [allowStatisticCookies, setAllowStatisticCookies] = useState(
    cookies["cookies-consent"]?.statistic ?? true,
  );
  const [allowMarketingCookies, setAllowMarketingCookies] = useState(
    cookies["cookies-consent"]?.marketing ?? true,
  );

  const saveCookiesConsent = (value: {
    preference: boolean;
    statistic: boolean;
    marketing: boolean;
  }) => {
    setCookie("cookies-consent", value, {
      expires: new Date(Date.now() + 1 * 30 * 24 * 60 * 60 * 1000),
    });
    setMustBeOpen(false);
    onOpenChange(false);
  };

  const allowCustomSelection = () => {
    saveCookiesConsent({
      preference: allowPreferenceCookies,
      statistic: allowStatisticCookies,
      marketing: allowMarketingCookies,
    });
  };

  const allowNecessaryOnly = () => {
    saveCookiesConsent({
      preference: false,
      statistic: false,
      marketing: false,
    });
  };

  const allowAll = () => {
    saveCookiesConsent({
      preference: true,
      statistic: true,
      marketing: true,
    });
  };

  return (
    <Dialog open={isOpen}>
      <DialogContent
        withoutCloseButton
        className="flex flex-col max-w-2xl gap-8 overflow-auto max-h-svh"
      >
        <div className="flex flex-col gap-4">
          <div className="text-xl font-medium">
            {t("components.molecules.cookie-banner.title")}
          </div>
          <div>{t("components.molecules.cookie-banner.description")}</div>
        </div>

        {isCustomOpen && (
          <div className="grid gap-4 sm:grid-cols-2 lg:grid-cols-4">
            <Label className="flex items-center space-x-2">
              <Switch disabled checked />
              <span>{t("components.molecules.cookie-banner.necessary")}</span>
            </Label>
            <Label className="flex items-center space-x-2">
              <Switch
                checked={allowPreferenceCookies}
                onCheckedChange={setAllowPreferenceCookies}
              />
              <span>{t("components.molecules.cookie-banner.preferences")}</span>
            </Label>
            <Label className="flex items-center space-x-2">
              <Switch
                checked={allowStatisticCookies}
                onCheckedChange={setAllowStatisticCookies}
              />
              <span>{t("components.molecules.cookie-banner.statistics")}</span>
            </Label>
            <Label className="flex items-center space-x-2">
              <Switch
                checked={allowMarketingCookies}
                onCheckedChange={setAllowMarketingCookies}
              />
              <span>{t("components.molecules.cookie-banner.marketing")}</span>
            </Label>
          </div>
        )}

        <div className="flex flex-col flex-shrink-0 gap-4 md:flex-row">
          <Button
            variant="outline"
            className="flex flex-shrink-0 md:flex-1"
            onClick={allowNecessaryOnly}
          >
            {t("components.molecules.cookie-banner.deny")}
          </Button>
          <Button
            onClick={() =>
              isCustomOpen ? allowCustomSelection() : setCustomOpen(true)
            }
            variant="outline"
            className="flex flex-shrink-0 md:flex-1"
          >
            {isCustomOpen
              ? t("components.molecules.cookie-banner.allowSelected")
              : t("components.molecules.cookie-banner.customize")}
          </Button>
          <Button
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className="flex flex-shrink-0 md:flex-1"
            onClick={allowAll}
          >
            {t("components.molecules.cookie-banner.allowAll")}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
