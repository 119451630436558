import { Link } from "@remix-run/react";
import { Carousel, CarouselContent, CarouselItem } from "../ui/carousel";
import Autoplay from "embla-carousel-autoplay";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { Partner } from "~/services/api";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import { cn } from "~/utils";

export type PartnersLayoutProps = {
  title: string;
  children?: ReactNode;
};

const PartnersLayout = ({ title, children }: PartnersLayoutProps) => {
  return (
    <div className="container">
      <div className="text-2xl font-medium text-center">{title}</div>
      {children}
    </div>
  );
};

export type PartnersFlexProps = {
  title: string;
  size: "sm" | "lg";
  partners?: Partner[];
};

const PartnersFlex = ({ title, partners, size }: PartnersFlexProps) => {
  return partners?.length ? (
    <PartnersLayout title={title}>
      <div className="flex flex-wrap justify-center">
        {partners.map((partner) => (
          <div
            className={cn("p-8", {
              "max-w-[250px]": size === "sm",
              "max-w-[350px]": size === "lg",
            })}
            key={partner.id}
          >
            {partner.url ? (
              <Link
                target="_blank"
                className="w-full"
                to={partner.url}
                rel="noreferrer"
              >
                <img
                  className="object-contain w-full aspect-square"
                  src={partner.image.original_url}
                  alt={partner.name}
                />
              </Link>
            ) : (
              <img
                className="object-contain w-full aspect-square"
                src={partner.image.original_url}
                alt={partner.name}
              />
            )}
          </div>
        ))}
      </div>
    </PartnersLayout>
  ) : null;
};

export type PartnersCarouselProps = {
  title: string;
  partners?: Partner[];
};

const PartnersCarousel = ({ title, partners }: PartnersCarouselProps) => {
  return partners?.length ? (
    <PartnersLayout title={title}>
      <Carousel
        opts={{ loop: true, align: "center", dragFree: true }}
        plugins={[
          Autoplay({ delay: 3000, stopOnInteraction: false }),
          WheelGesturesPlugin(),
        ]}
      >
        <CarouselContent>
          {partners.map((partner) => (
            <CarouselItem
              className="p-8 basis-1/3 md:basis-1/5 lg:basis-[14.28%]"
              key={partner.id}
            >
              {partner.url ? (
                <Link
                  target="_blank"
                  className="w-full"
                  to={partner.url}
                  rel="noreferrer"
                >
                  <img
                    className="object-contain w-full aspect-square"
                    src={partner.image.original_url}
                    alt={partner.name}
                  />
                </Link>
              ) : (
                <img
                  className="object-contain w-full aspect-square"
                  src={partner.image.original_url}
                  alt={partner.name}
                />
              )}
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </PartnersLayout>
  ) : null;
};

export type PartnersProps = {
  partners?: Partner[];
};

export const Partners = ({ partners }: PartnersProps) => {
  const { t } = useTranslation("common");

  const generalPartners = partners?.filter((p) => p.type === "general") ?? [];
  const mainPartners = partners?.filter((p) => p.type === "main") ?? [];
  const basicPartners =
    partners?.filter((p) => !p.type || p.type === "other") ?? [];

  return partners?.length ? (
    <div className="py-16 overflow-hidden text-white bg-neutral-900">
      <div className="container flex flex-col items-center gap-12">
        <h3 className="text-4xl font-heading">
          {t("components.blocks.partners.partners")}
        </h3>

        <PartnersFlex
          title={t("components.blocks.partners.generalPartners")}
          size="lg"
          partners={generalPartners}
        />
        <PartnersFlex
          title={t("components.blocks.partners.mainPartners")}
          size="sm"
          partners={mainPartners}
        />
        <PartnersCarousel
          title={t("components.blocks.partners.partners")}
          partners={basicPartners}
        />
      </div>
    </div>
  ) : null;
};
